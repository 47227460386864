@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600;700;800&display=swap");

body {
  font-family: "Poppins", sans-serif;
}

.login-container {
  width: 100vw;
  height: 100vh;
}
.login-background {
  background-image: url("./../src/assets/images/bg-new.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.employer-signup {
  background-image: url("./../src/assets/images/bg-new-2.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

.home-container {
  min-height: 100%;
  background-image: url("./../src/assets/images/homebg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.home-container2 {
  min-height: 100%;
  background-image: url("./../src/assets/images/communitybg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.footer-container {
  min-height: 100%;
  background-image: url("./../src/assets/images/footerimage.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.login-page-container {
  background-image: url("./../src/assets/images/LineShape.png");
  background-size: cover;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.register-page-container {
  background-image: url("./../src/assets/images/registerBackground.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.scheduling-page-container {
  background-image: url("./../src/assets/images/SchedulingPageImage.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.faq-page-container {
  background-image: url("./../src/assets/images/faq.png");
  background-size: cover;
  background-position: bottom left;
  background-repeat: no-repeat;
}
.faq-page-container2 {
  background-image: url("./../src/assets/images/faq2.png");
  background-size: cover;
  background-position: bottom left;
  background-repeat: no-repeat;
}

.optpage-background {
  background-image: url("./../src/assets/images/YellowLines.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
/* .topcandidate-background {
  background-image: url("./../src/assets/images/yellowbg.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
} */
.dashboard-background {

  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.employer-dashboard-background {
  background-image: url("./../src/assets/images/empdashboardbg.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.bmg-dashboard-background {
  background-image: url("./../src/assets/images/bmgbg1.png");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}

/* Calendar CSS */
/* .custom-calendar .react-datepicker {
  width: 100%;
}
.custom-calendar .react-datepicker__month-container {
  width: 100%;
} */
.custom-calendar .react-datepicker__current-month {
  font-size: 23px;
}
@media (min-width: 568px) {
  .custom-calendar .react-datepicker__day-name {
    width: 3.5rem;
    color: white;
  }
  .custom-calendar .react-datepicker__day {
    width: 3.5rem;
  }
}
.custom-calendar .react-datepicker__day-name {
  
  color: white;
}
.custom-calendar .react-datepicker__day--selected:hover {
  background-color: #e9aa06;
}
.custom-calendar .react-datepicker {
  font-size: 1rem;
  background-color: #fff;

  border: none !important;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}
.custom-calendar .react-datepicker__header {
  text-align: center;
  background-color: #e9aa06;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding: 17px 0;
  position: relative;
}
.custom-calendar .react-datepicker__day--keyboard-selected {
  background-color: inherit;
}

.custom-calendar .react-datepicker__day--selected {
  border-radius: 0.3rem;
  background-color: #e9aa06;
  color: #fff;
}

.custom-calendar .react-datepicker__day--keyboard-selected:hover {
  background-color: #e9aa06;
}
.custom-calendar .react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.5rem;
}

/* .toastify-color-progress-light: linear-gradient(to right,
  #4cd964,
  #5ac8fa,
  #007aff,
  #34aadc,
  #5856d6,
  #ff2d55
); */

/* Overlay for sidebar on smaller screens */
/* zoom screen css */
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #e9aa06 !important;
  --mdc-fab-icon-color: #fff;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-form-field {
  --mat-mdc-form-field-floating-label-scale: 0.75;
  display: inline-flex;
  flex-direction: column;
  min-width: 0;
  text-align: left;
  margin-top: 20px;
}
.mat-icon {
  -webkit-user-select: none;
  user-select: none;
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  height: 24px;
  width: 24px;
  overflow: hidden;
  color: white;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #e9aa06 !important;
  --mdc-slider-focus-handle-color: #e9aa06 !important;
  --mdc-slider-hover-handle-color: #e9aa06 !important;
  --mdc-slider-active-track-color: #e9aa06 !important;
  --mdc-slider-inactive-track-color: #e9aa06 !important;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #e9aa06 !important;
  --mat-mdc-slider-ripple-color: #e9aa06 !important;
  --mat-mdc-slider-hover-ripple-color: rgba(13, 114, 237, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(13, 114, 237, 0.2);
}
.mat-mdc-progress-bar.mdc-linear-progress__buffer-bar {
  background-color: red !important;
}
.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #e9aa06 !important;
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: #e7d4a6 !important;
}

:where(.css-dev-only-do-not-override-1b0bdye).ant-btn {
  font-size: 14px;
  line-height: 1.5714285714285714;
  height: 32px;
  padding: 4px 15px;
  background: #e9aa06 !important;
  border-radius: 6px;
}



/* for file upload /////////////////// */

.file-btn {
  width: 330px;
  height: 150px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: #E9AA06;
  background-color: #fff;
  border: 1.5px dashed #E9AA06;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.file-btn:hover {
  color: #E9AA06;
  background-color: #fff;
}

.file-btn span {
  width: 50px;
  height: 50px;
  font-size: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #E9AA06;
}

.file-card {
  width: 300px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #000;
  background-color: #fff;
  border: 1px solid #E9AA06;
  border-radius: 6px;
  padding: 8px 15px;
}

.file-info {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 15px;
}

.file-info h6 {
  flex: 1;
  font-size: 13px;
  font-weight: 400;
}

.progress-bg {
  width: 100%;
  height: 5px;
  background-color: rgba(0, 0, 0, 0.076);
  border-radius: 8px;
  margin-top: 8px;
}

.progress {
  width: 0%;
  height: 5px;
  background-color: #E9AA06;
  border-radius: 8px;
  transition: width 0.5s ease;
}

.icon {
  font-size: 30px;
  color: white;
}

.close-icon {
  font-size: 18px;
  cursor: pointer;
}

.file-info button,
.check-circle {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: white;
  background-color: #E9AA06;
  border: none;
  border-radius: 30px;
}

.upload-btn {
  width: 300px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #E9AA06;
  border: none;
  border-radius: 8px;
  padding: 10px;
  margin-top: 15px;
  cursor: pointer;
}
